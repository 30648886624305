/**********************************
 * Color definitions
 **********************************/
// $stng-dark-900: #000e14;
$stng-dark-900: #05070a;
// $stng-dark-800: #001d28;
// $stng-dark-700: #002c3c;
// $stng-dark-600: #003b50;
$stng-dark-600: #151f2a;
// $stng-dark-500: #004a65;
// $stng-dark-400: #005879;
//$stng-dark-300: #00678d;
$stng-dark-300: #25374a;
// $stng-dark-200: #0076a1;
// $stng-dark-100: #0085b5;
// $stng: #0094ca;
$stng: #364f6b;
// $stng-light-100: #199ecf;
// $stng-light-200: #32a9d4;
// $stng-light-300: #4cb4d9;
$stng-light-300: #728397;
// $stng-light-400: #66bedf;
// $stng-light-500: #7fc9e4;
// $stng-light-600: #99d4e9;
$stng-light-600: #aeb8c3;
// $stng-light-700: #b2deef;
// $stng-light-800: #cce9f4;
// $stng-light-900: #e5f4f9;
$stng-light-900: #eaedf0;

/**********************************
 * Colors
 **********************************/
$colors: (
  'stng-darkest': $stng-dark-900,
  //    $stng-dark-800:
  //    $stng-dark-700:
  'stng-darker': $stng-dark-600,
  //    $stng-dark-500:
  //    $stng-dark-400:
  'stng-dark': $stng-dark-300,
  //    $stng-dark-200:
  //    $stng-dark-100:
  'stng': $stng,
  //    $stng-light-100:
  //    $stng-light-200:
  'stng-light': $stng-light-300,
  //    $stng-light-400:
  //    $stng-light-500:
  'stng-lighter': $stng-light-600,
  //    $stng-light-700:
  //    $stng-light-800:
  'stng-lightest': $stng-light-900,
);

/**********************************
 * Theme colours
 **********************************/
$theme-colors: (
  //    "excel": $green,
  'stng-darkest': $stng-dark-900,
  //    $stng-dark-800:
  //    $stng-dark-700:
  'stng-darker': $stng-dark-600,
  //    $stng-dark-500:
  //    $stng-dark-400:
  'stng-dark': $stng-dark-300,
  //    $stng-dark-200:
  //    $stng-dark-100:
  'stng': $stng,
  //    $stng-light-100:
  //    $stng-light-200:
  'stng-light': $stng-light-300,
  //    $stng-light-400:
  //    $stng-light-500:
  'stng-lighter': $stng-light-600,
  //    $stng-light-700:
  //    $stng-light-800:
  'stng-lightest': $stng-light-900
);

/**********************************
 * Sizes
 **********************************/
$sizes: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 35%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%,
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #f8f9fa; // $gray-100;
$body-color: #212529; // $gray-900;

// Links
//
// Style anchor elements.

$link-color: #47688d; // $stng;

@import 'bootstrap';
// Required
// @import '_functions';
// @import '_variables';
// @import '_mixins';

// Optional;
// @import '_root';

/**********************************
 * Reboot
 **********************************/
// @import '_reboot';
html,
body,
#root {
  height: 100%;
}

// @import '_type';
// @import '_images';
// @import '_code';
// @import '_grid';
// @import '_tables';
// @import '_forms';
// @import '_buttons';
// @import '_transitions';
// @import '_dropdown';
// @import '_button-group';
// @import '_input-group';
// @import '_custom-forms';

/**********************************
 * Navigation
 **********************************/
// @import '_nav';
// @import '_navbar';
.navbar {
  min-height: 3.5rem;
}
.navbar-toggler {
  border: none;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);
}
.navbar-dark .navbar-toggler {
  color: $white;
}

// @import '_card';
// @import '_breadcrumb';
// @import '_pagination';
// @import '_badge';
// //@import "jumbotron";
// @import '_alert';
// //@import "progress";
// @import '_media';
// @import '_list-group';
// @import '_close';
// @import '_toasts';
// @import '_modal';
// @import '_tooltip';
// @import '_popover';
// //@import "carousel";
// //@import "spinners";
// @import '_utilities';
// //@import "print";

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

/* React vis */
//@import "node_modules/react-vis/dist/style"

/**********************************
 * Toggle sidemenu
 **********************************/

// #sideBarMenu {
//    min-width: 200px;
//     max-width: 200px;
//     transition: margin-left 0.3s;
// }
//
// #sideBarMenu.active {
//     margin-left: -200px;
//     border-bottom: 1px solid #47748b;
// }
//
//
//  @media (max-width: 576px) {
//     #sideBarMenu {
//         margin-left: -200px;
//         transition: none;
//     }
//
//     #sideBarMenu.active {
//         margin-left: 0;
//     }
// }

#sideBarMenu {
  -webkit-transition: width 0.5s, opacity 0.25s;
  -moz-transition: width 0.5s, opacity 0.25s;
  -o-transition: width 0.5s, opacity 0.25s;
  transition: width 0.5s, opacity 0.25s;
}

.tooltip-inner {
  max-width: 400px;
  /* If max-width does not work, try using width instead */
  /*    width: 350px;*/
}

.alert-fixed {
  top: 60px;
  right: 0;
  z-index: 9999;
  max-width: 1024px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
