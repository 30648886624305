/*.form-signin {
  width: 100%;
  max-width: 330px;
  padding-top: 15px;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type='text'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}*/

.btn-login:hover {
  background-color: #fbd758 !important;
  color: #303030;
  border-color: #fce38a;
}
